<template>
  <header>
    <div
      class="overlay clear"
      v-if="showLogin || showShopDrop"
      @click="(showLogin = false), (showShopDrop = false)"
    ></div>
    <div class="header-container">
      <div class="header-section-1-3">
        <a href="https://eventyrgames.com" target="_blank"
          ><img class="logo-image" src="@\assets\eg_logo_white.png"
        /></a>
      </div>
      <div class="header-section-2-3">
        <div class="top-tagline">Want to Trade Magic Items in 5E?</div>
        <div class="wgmm-link">
          Check out
          <a
            href="https://eventyrgames.store/products/wanderers-guide-to-merchants-magic"
            target="_blank"
          >
            Wanderer's Guide to Merchants & Magic!</a
          >
        </div>
      </div>
      <div class="header-section-3-3">
        <i
          class="fas fa-store store-icon"
          @click.self="showShopDrop = !showShopDrop"
          :class="{ active: $store.state.selectedShop != null }"
          ><transition name="fade">
            <ShopDropList
              v-if="showShopDrop"
              @showShopMenu="(showShopMenu = true), (showShopDrop = false)"
              @close="showShopDrop = false"
            ></ShopDropList> </transition
        ></i>
        <i
          class="fa fa-user"
          @click="showLogin = !showLogin"
          :class="{ active: logged_in }"
          ><transition name="fade">
            <div class="drop-menu" v-if="showLogin">
              <div v-if="logged_in">Profile</div>
              <div
                class="login-button"
                @click="logged_in ? signOut() : goToLogin()"
              >
                {{ logged_in ? "Log Out" : "Login/Signup" }}
              </div>
            </div></transition
          >
        </i>
      </div>
    </div>
    <ShopMenu v-if="showShopMenu" @close="showShopMenu = false"></ShopMenu>
  </header>
</template>

<script>
import firebase from "firebase";
import ShopMenu from "./ShopMenu.vue";
import ShopDropList from "./ShopDropList.vue";

export default {
  name: "BaseHeader",
  components: { ShopMenu, ShopDropList },
  computed: {
    logged_in() {
      return this.$store.state.logged_in;
    },
  },
  email() {
    return this.$store.state.user.email;
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("SET_LOGGED_IN", false);
          this.$store.commit("SET_USER", null);
          this.$router.go();
        });
    },
  },
  data() {
    return {
      showLogin: false,
      showShopDrop: false,
      showShopMenu: false,
      shopNewName: "",
      showDeletePopup: false,
      shopToDelete: "",
      editShop: null,
    };
  },
};
</script>

<style>
header {
  width: 100%;
  background: linear-gradient(90deg, #2f2f3d, #0b0b16);
  height: 70px;
  display: flex;
  justify-content: center;
}

.header-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.header-section-1-3,
.header-section-3-3 {
  width:25%;
}
.header-section-1-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-section-2-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:50%;
  text-align:center;
}
.header-section-3-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header-section-3-3 i {
  font-size: 50px;
  margin: 0px 10px;
  position: relative;
}

.top-tagline {
  font-weight: 600;
}

.wgmm-link {
  margin-top: 5px;
  font-size: 0.8rem;
}

.wgmm-link a {
  font-weight: 600;
  color: goldenrod;
}

.logo-image {
  width: 60px;
  height: auto;
  margin: 0px 0px 0px 25px;
}

.active {
  color: rgb(65, 196, 65);
}

.drop-menu {
  font-family: montserrat, sans-serif;
  color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 65px;
  right: -50px;
  width: 150px;
  font-size: 0.9rem;
  background: linear-gradient(90deg, #2f2f3d, #0b0b16);
  padding: 10px;
  font-weight: 100;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  user-select: none;
  z-index: 100;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.144);
}

.drop-menu i {
  font-size: 1.1rem;
  padding: 5px;
  margin: 5px 0px 5px 5px;
  border-radius: 5px;
}

.drop-menu .fa-trash:hover {
  color: #0b0b16;
  background: rgb(184, 0, 0);
}

.drop-menu .fa-edit:hover {
  color: #0b0b16;
  background: rgb(15, 131, 0);
}

.drop-menu.wide {
  width: 200px;
  right: -75px;
}

.login-button {
  padding: 5px 10px;
  font-weight: 600;
  background: white;
  color: black;
  margin: 5px 5px;
  border-radius: 5px;
}

.shop-row .fas {
  transform: scaleX(0);
  width: 0;
  transition: all 0.3s ease;
  padding: 0px;
  margin: 5px 0px;
  padding: 5px 0px;
}

.shop-row:hover .fas {
  transform: scaleX(1);
  width: 10%;
  transition: all 0.3s ease;
  padding: 5px;
  margin: 5px 0px 5px 5px;
}

.shop-select {
  width: 100%;
  margin: 5px 0px;
  padding: 5px;
  background: #ffffff;
  color: #0b0b16;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.shop-select:hover {
  color: #ffffff;
  background: #0b0b16;
  transition: all 0.3s ease;
}

.store-icon {
  z-index:100;
}

@media only screen and (max-width: 600px) {

  .store-icon {
    display:none;
  }

.drop-menu.wide {
  position:fixed;
  bottom: 100px;
  top:auto;
  left:0;
  right:0;
  margin:0 auto;
  width: 80vw;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.144);
}

.drop-menu.wide h3 {
  font-size:1.2rem;
}

  .logo-image {
    width: 40px;
    height: auto;
    margin: 0px 0px 0px 10px;
  }

  .fa-user {
    font-size: 30px;
    margin: 0px 10px 0px 0px;
    position: relative;
  }
  .top-tagline {
    font-weight: 600;
    font-size: 0.8rem;
  }

  .wgmm-link {
    margin-top: 5px;
    font-size: 0.6rem;
  }
}
</style>