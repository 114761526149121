<template>
  <div class="container">
    <div class="overlay" @click="$emit('close')"></div>
    <div class="menu">
 <div class="menu-row menu-header centered" @click.self="showInventoryFields = !showInventoryFields">
        <h2 @click.self="showInventoryFields = !showInventoryFields">Inventory</h2>
        <div class="filter-random">
          <i class="fas fa-dice-d20" @click="shuffleInventory()"></i>
        </div>
      </div>

      <transition name="drop-expand" mode="out-in">
      <div class="menu-section" v-if="showInventoryFields">
      <div class="menu-row fields">
        <div class="filter">
          <h3>Preset</h3>
          <select
            class="inventory-select"
            v-model="shopInventory"
            name="inventory"
            @change="onChange($event)"
          >
            <option value="None">None</option>
            <option value="Town">Town</option>
            <option value="City">City</option>
            <option value="Metropolis">Metropolis</option>
          </select>
        </div>
        <div class="filter">
          <div class="menu-row left inventory-size">
            <h3>Items</h3>
            <div class="number-options">
              <div
                class="number-option"
                :class="{ highlight: numberOptionDice }"
                @click="(numberOptionDice = true), (numberOptionFixed = false)"
              >
                Roll
              </div>
              <div
                class="number-option"
                :class="{ highlight: numberOptionFixed }"
                @click="(numberOptionFixed = true), (numberOptionDice = false)"
              >
                Fixed
              </div>
            </div>
          </div>
          <div class="number-items">
            <transition name="fade" mode="out-in" v-if="numberOptionDice">
              <div class="dice-options">
                <select name="dice" v-model="dice" class="dice-select">
                  <option value="4">d4</option>
                  <option value="6">d6</option>
                  <option value="8">d8</option>
                  <option value="10">d10</option>
                  <option value="12">d12</option>
                  <option value="20">d20</option>
                </select>
                <select
                  name="modifier"
                  v-model="modifier"
                  class="modifier-select"
                  v-if="numberOptionDice"
                >
                  <option value="+">+</option>
                  <option value="-">–</option>
                </select>
              </div>
            </transition>
            <input type="number" class="text-input" v-model="nItems" min="0" />
          </div>
        </div>

        <div class="filter" @mouseleave="showSourceFilters = false">
          <h3>Sources</h3>
          <div class="selected-filters" @mouseover="showSourceFilters = true">
            <div
              v-for="source in includedSources"
              v-bind:key="source"
              @click="filterSources(source)"
              class="filter-element"
            >
              <i class="fas fa-times"></i>{{ source }}
            </div>
            <transition name="fade" mode="out-in">
              <div
                class="filter-options"
                v-if="showSourceFilters & (excludedSources.length > 0)"
              >
                <div
                  v-for="source in excludedSources"
                  v-bind:key="source"
                  @click="filterSources(source)"
                  class="filter-element"
                  :class="{ filtered: includedSources.includes(source) }"
                >
                  {{ source }}
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="menu-row fields">
        <div class="filter" @mouseleave="showTypeFilters = false">
          <h3>Item Types</h3>
          <div class="selected-filters" @mouseover="showTypeFilters = true">
            <div
              @click="filterType(type)"
              class="filter-element"
              v-for="type in includedTypes"
              v-bind:key="type"
            >
              <i class="fas fa-times"></i>{{ type }}
            </div>
            <transition name="fade" mode="out-in">
              <div
                class="filter-options"
                v-if="showTypeFilters && excludedTypes.length > 0"
              >
                <div
                  v-for="type in excludedTypes"
                  v-bind:key="type"
                  @click="filterType(type)"
                  class="filter-element"
                  :class="{ filtered: includedTypes.includes(type) }"
                >
                  {{ type }}
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="filter" @mouseleave="showRarityFilters = false">
          <h3>Rarities</h3>
          <div class="selected-filters" @mouseover="showRarityFilters = true">
            <div
              @click="filterRarity(rarity)"
              class="filter-element"
              v-for="rarity in includedRarities"
              v-bind:key="rarity"
            >
              <i class="fas fa-times"></i>{{ rarity }}
            </div>
            <transition name="fade" mode="out-in">
              <div
                class="filter-options"
                v-if="showRarityFilters & (excludedRarities.length > 0)"
              >
                <div
                  v-for="rarity in excludedRarities"
                  v-bind:key="rarity"
                  @click="filterRarity(rarity)"
                  class="filter-element"
                  :class="{ filtered: includedRarities.includes(rarity) }"
                >
                  {{ rarity }}
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="filter">
          <h3>Item Value</h3>
          <div class="menu-row centered number-inputs">
            <input
              type="number"
              class="number-input"
              v-model="value1"
              placeholder="0"
            />
            <h3 class="dash">–</h3>
            <input
              type="number"
              class="number-input"
              v-model="value2"
              placeholder="Any"
            />
          </div>
        </div>
      </div>
      </div>
      </transition>

<!-- SHOPKEEPER FIELDS -->

      <div class="menu-row centered menu-header" @click.self="showShopkeeperFields = !showShopkeeperFields">
        <h2 @click.self="showShopkeeperFields = !showShopkeeperFields">Shopkeeper</h2>
        <div class="filter-random">
          <i class="fas fa-dice-d20" @click="shuffleShopkeeper()"></i>
        </div>
      </div>
      <transition name="drop-expand">
      <div class="menu-section" v-if="showShopkeeperFields">
      <div class="menu-row fields">
        <div class="filter">
          <h3>Name</h3>
          <input
            class="text-input"
            type="text"
            v-model="shopkeeperName"
            placeholder="Shopkeeper's Name"
          />
        </div>
        <div class="filter">
          <h3>Race/Type</h3>
          <select
            class="text-input"
            v-model="shopkeeperRace"
            placeholder="Shopkeeper's Race"
          >
            <option v-for="race in shopkeeperRaces" v-bind:key="race">
              {{ race }}
            </option>
          </select>
        </div>
        <div class="filter">
          <h3>Background</h3>
          <select
            class="text-input"
            v-model="shopkeeperBackground"
            placeholder="Shopkeeper's Background"
          >
            <option
              v-for="background in shopkeeperBackgrounds"
              v-bind:key="background"
            >
              {{ background }}
            </option>
          </select>
        </div>
                <div class="filter">
          <h3>Appearance</h3>
          <select
            class="text-input"
            v-model="shopkeeperAppearance"
            placeholder="Shopkeeper's Appearance"
          >
            <option
              v-for="appearance, index in shopkeeperAppearances"
              v-bind:key="appearance"
              :value="index"
            >
              {{ appearance }}
            </option>
          </select>
        </div>
      </div>
      <div class="menu-row fields">
        <div class="filter">
          <h3>Personality</h3>
          <div class="menu-row number-inputs">
            <select
              class="text-input"
              v-model="shopkeeperPersonality1"
              placeholder="Personality Trait"
            >
              <option
                v-for="personality in shopkeeperPersonalities1"
                v-bind:key="personality"
              >
                {{ personality }}
              </option>
            </select>
            <select
              class="text-input"
              v-model="shopkeeperPersonality2"
              placeholder="Personality Trait"
            >
              <option
                v-for="personality in shopkeeperPersonalities2"
                v-bind:key="personality"
              >
                {{ personality }}
              </option>
            </select>
          </div>
        </div>
        <div class="filter">
          <h3>Biases</h3>
          <div class="menu-row number-inputs">
            <i class="fas fa-plus positive"></i
            ><select
              class="text-input"
              v-model="shopkeeperBias1"
              placeholder="Positive Bias"
            >
              <option v-for="bias in shopkeeperBiases1" v-bind:key="bias">
                {{ bias }}
              </option>
            </select>
            <i class="fas fa-minus negative"></i>
            <select
              class="text-input"
              v-model="shopkeeperBias2"
              placeholder="Negative Bias"
            >
              <option v-for="bias in shopkeeperBiases2" v-bind:key="bias">
                {{ bias }}
              </option>
            </select>
          </div>
        </div>
      </div>
      </div>
      </transition>

<!-- SHOP DESCRIPTION -->

      <div class="menu-row menu-header centered" @click.self ="showShopFields = !showShopFields">
        <h2 @click.self ="showShopFields = !showShopFields">Shop Description</h2>
        <div class="filter-random">
          <i class="fas fa-dice-d20" @click="shuffleShop()"></i>
        </div>
      </div>
      <transition name="drop-expand">
      <div class="menu-section" v-if="showShopFields">
      <div class="menu-row fields">
        <div class="filter">
          <h3>Name</h3>
          <input
            class="text-input"
            type="text"
            v-model="name"
            placeholder="Your Shop's Name"
          />
        </div>
        <div class="filter">
          <h3>Location</h3>
          <select
            class="text-input"
            v-model="shopLocation"
            placeholder="Shop Location"
          >
            <option
              v-for="shopLocation in shopLocations"
              v-bind:key="shopLocation"
            >
              {{ shopLocation }}
            </option>
          </select>
        </div>
        </div>


      <div class="menu-row fields">
        <div class="filter">
          <h3>Appearance</h3>
          <div class="menu-row number-inputs">
            <select
              class="text-input"
              v-model="shopAppearance1"
              placeholder="Shop Appearance"
            >
              <option
                v-for="shopAppearance in shopAppearances1"
                v-bind:key="shopAppearance"
              >
                {{ shopAppearance }}
              </option>
            </select>
            <select
              class="text-input"
              v-model="shopAppearance2"
              placeholder="Shop Appearance"
            >
              <option
                v-for="shopAppearance in shopAppearances2"
                v-bind:key="shopAppearance"
              >
                {{ shopAppearance }}
              </option>
            </select>
          </div>
        </div>

        <div class="filter">
          <h3>Quirk</h3>
          <select
            class="text-input"
            v-model="shopQuirk"
            placeholder="Shop Quirks"
          >
            <option
              v-for="(shopQuirk, index) in shopQuirks"
              v-bind:key="shopQuirk"
              :value="index"
            >
              {{ shopQuirk }}
            </option>
          </select>
        </div>
        <div class="filter">
          <h3>Protection</h3>
          <select
            class="text-input"
            v-model="shopProtection"
            placeholder="Shop Protections"
          >
            <option
              :value="index"
              v-for="(shopProtection, index) in shopProtections"
              v-bind:key="shopProtection"
            >
              {{ shopProtection }}
            </option>
          </select>
        </div>
      </div>
      </div>
      </transition>

            <div class="menu-row">
        <div class="warning" v-if="showNonameWarning">Please input a name.</div>
        <div class="warning" v-if="showTooManyShopsWarning">
          You already have 10 shops. Delete some shops to create more.
        </div>
        <div class="warning" v-if="showDuplicateWarning">
          You already have a shop with that name. Please choose another name.
        </div>
      </div>
      <div class="menu-row">
        <div class="save-button" @click="generateShop">
          Generate Shop
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopMenu",
  emits: ["close"],
  data() {
    return {
      items: this.$store.state.items,
      name: "",
      shopInventory: "None",
      showTypeFilters: false,
      showRarityFilters: false,
      showSourceFilters: false,
      showShopkeeperFields: false,
      showShopFields: false,
      showInventoryFields:true,
      includedTypes: [],
      value1: null,
      value2: null,
      numberOptionFixed: false,
      numberOptionDice: true,
      shopkeeperName: "",
      shopkeeperRace: "",
      shopkeeperBackground: "",
      shopkeeperAppearance: "",
      shopkeeperPersonality1: "",
      shopkeeperPersonality2: "",
      shopkeeperQuirk: "",
      shopkeeperBias1: "",
      shopkeeperBias2: "",
      shopLocation: "",
      shopAppearance1: "",
      shopAppearance2: "",
      shopQuirk: "",
      shopProtection: "",
      sources: ["DMG", "WGMM", "XGTE", "TCOE", "Custom"],
      types: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      rarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
      shopkeeperNames: [
        "Alerak",
        "Alos",
        "Doxith",
        "Erennith",
        "Ezziah",
        "Famil",
        "Harrizan",
        "Imellin",
        "Isoldir",
        "Lorrell",
        "Malin",
        "Markon",
        "Mithran",
        "Perrinar",
        "Qvinth",
        "Reshin",
        "Umês",
        "Xorellion",
        "Ylithis",
        "Zofir",
      ],
      shopnamePrefixes: [
        "Awesome",
        "Magical",
        "Fantastic",
        "Mysterious",
        "Delightful",
        "Unique",
        "Curious",
        "Extraordinary",
        "Enchanted",
        "Charming",
        "Exotic",
      ],
      shopnameSuffixes: [
        "Curiosities",
        "Shop",
        "Shoppe",
        "Dispensary",
        "Goods",
        "Artifacts",
        "Relics",
        "Discoveries",
      ],
      shopLocations: [
        "Shop",
        "House",
        "Market stall",
        "Workshop",
        "Laboratory",
        "Smithy",
        "Mansion",
        "Inn",
        "Tavern",
        "Temple",
        "Tower",
        "Castle",
        "Garden",
        "Ship",
        "Cave",
        "Carriage",
        "Hut",
        "Treehouse",
        "Gallery",
        "Ruins",
      ],
      shopAppearances1: [
        "Cluttered",
        "Minimalistic",
        "Dark",
        "Bright",
        "Decrepit",
        "Small",
        "Beautiful",
        "Large",
      ],
      shopAppearances2: [
        "Messy",
        "Organized",
        "Damp",
        "Colorful",
        "Dusty",
        "Cozy",
        "Luxurious",
        "Spacious",
      ],
      shopQuirks: [
        "Bad Smell",
        "Good Smell",
        "Invisible Servants",
        "Warm",
        "Cold",
        "Exotic Pet",
        "Clockwork Toy",
        "Repeating Parrot",
        "Silent",
        "Humid",
      ],
      shopQuirksLong: [
        "smells like rotten eggs",
        "smells like fresh baking",
        "has shelves stocked by invisible servants",
        "is unnaturally warm",
        "is unnaturally cold",
        "has a dog that growls at bad offers",
        "has a clockwork toy that claps at good offers",
        "has a parrot that repeats the merchant’s offers",
        "is unnaturally silent",
        "is as humid as a jungle in the rain season",
      ],
      shopProtections: [
        "Illusory Items",
        "Cursed Items",
        "Extradimensional Items",
        "Item Alarms",
        "Self-destructing Items",
        "Antimagic Field",
        "Mercenaries",
        "Guardians",
      ],
      shopProtectionsLong: [
        "all the items on display are illusions",
        "all the items are cursed until paid for",
        "all the items are kept in extradimensional spaces",
        "all the items make loud noises when moved",
        "all the items blow up if the merchant dies",
        "has an antimagic field that prevents spellcasting",
        "the owner will hire mercenaries to catch thieves",
        "is protected by fearsome magical constructs",
      ],
      shopkeeperRaces: [
        "Human",
        "Half-Elf",
        "Half-orc",
        "High Elf",
        "Wood Elf",
        "Dark Elf",
        "Dwarf",
        "Duergar",
        "Gnome",
        "Halfling",
        "Tiefling",
        "Dragonborn",
      ],
      shopkeeperBackgrounds: [
        "Adventurer",
        "Sailor",
        "Noble",
        "Priest",
        "Mercenary",
        "Urchin",
        "Burglar",
        "Wizard",
        "Scholar",
        "Author",
        "Musician",
        "Traveler",
        "Soldier",
        "Court advisor",
        "Gladiator",
        "Inventor",
        "Knight",
        "Art collector",
        "Diplomat",
      ],
      shopkeeperPersonalities1: [
        "Inquisitive",
        "Friendly",
        "Irritable",
        "Arrogant",
        "Boastful",
        "Humorless",
        "Sesquipedalian",
        "Pompous",
        "Suspicious",
        "Naive",
      ],
      shopkeeperPersonalities2: [
        "Philosophical",
        "Flustered",
        "Rude",
        "Courteous",
        "Introverted",
        "Self-deprecating",
        "Excited",
        "Impatient",
        "Greedy",
        "Awkward",
      ],
      shopkeeperAppearances: [
        "Wooden Leg",
        "Eyepatch",
        "Missing Teeth",
        "Facial Scar",
        "Slackjawed",
        "Milky-white Eyes",
        "Poor Posture",
        "Haunting Beauty",
        "Colorful Hair",
        "Colorful Tattoos",
        "Piercings",
        "Tall and Lanky",
        "Short and Squat",
        "Brawny",
        "Malnourished",
        "Bulging Eyes",
        "Oversized Glasses",
        "Heavy Makeup",
        "Dressed in Rags",
        "Flamboyant",
      ],
      shopkeeperAppearancesLong: [
        "has a Wooden leg",
        "has an Eyepatch",
        "is Missing several teeth",
        "has a gruesome Facial scar",
        "has a Slackjawed expression",
        "has Milky-white eyes",
        "has a Poor and crooked posture",
        "is Eerily beautiful",
        "has bright, Colorful hair",
        "has Colorful tattoos",
        "has many Piercings",
        "is Tall and lanky",
        "is Short and squat",
        "is Brawny",
        "is gaunt and Malnourished",
        "has Bulging eyes",
        "wears Oversized glasses",
        "wears Garish and clumsy makeup",
        "wears Ragged clothes",
        "wears Flamboyant clothes",
      ],
      shopkeeperQuirks: [
        "Stutters",
        "Lisps",
        "Speaks quickly",
        "Speaks slowly",
        "Speaks too softly",
        "Speaks too loudly",
        "Speaks nasally",
        "Stands too close",
        "Jokes a lot",
        "Mumbles a lot",
        "Coughs a lot",
        "Rhymes a lot",
        "Interrupts a lot",
        "Laughs a lot",
        "Fidgets a lot",
        "Forgets names",
        "Repeats words",
        "Hears poorly",
        "Gives nicknames",
        "Has nervous tics",
      ],
      shopkeeperBiases1: [
        "Humans",
        "Dwarves",
        "Gnomes",
        "Dragonborn",
        "Bards and musicians",
        "Druids and rangers",
        "Warlocks and sorcerers",
        "The Young",
        "The Attractive",
        "The Poor",
      ],
      shopkeeperBiases2: [
        "Elves",
        "Halflings",
        "Tieflings",
        "Barbarians and fighters",
        "Clerics and paladins",
        "Monks and rogues",
        "Wizards",
        "The Old",
        "The Rich",
        "The Pious",
      ],
      shopkeeperDescription: "",
      includedRarities: [],
      includedSources: [],
      nItems: 1,
      showNonameWarning: false,
      showTooManyShopsWarning: false,
      showDuplicateWarning: false,
      dice: 6,
      modifier: "+",
    };
  },
  mounted() {
    this.shuffleInventory();
    this.shuffleShopkeeper();
    this.shuffleShop();
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    filterType(type) {
      if (!this.includedTypes.includes(type)) {
        this.includedTypes.push(type);
      } else {
        this.includedTypes = this.includedTypes.filter(
          (filteredType) => filteredType != type
        );
      }
    },
    filterRarity(rarity) {
      if (!this.includedRarities.includes(rarity)) {
        this.includedRarities.push(rarity);
      } else {
        this.includedRarities = this.includedRarities.filter(
          (filteredRarity) => filteredRarity != rarity
        );
      }
    },
    filterSources(source) {
      if (!this.includedSources.includes(source)) {
        this.includedSources.push(source);
      } else {
        this.includedSources = this.includedSources.filter(
          (filteredSource) => filteredSource != source
        );
      }
    },
    async generateShop() {
      if (!this.name || this.name.length < 1) {
        this.showNonameWarning = true;
      } else if (
        this.$store.state.customShops &&
        this.$store.state.customShops.length >= 10
      ) {
        this.showTooManyShopsWarning = true;
      } else if (
        this.$store.state.customShops.some((shop) => shop.name == this.name)
      ) {
        this.showDuplicateWarning = true;
      } else {
        let shopDescription =
          this.name +
          " is a " +
          this.shopAppearance1.toLowerCase() +
          " and " +
          this.shopAppearance2.toLowerCase() +
          " " +
          this.shopLocation.toLowerCase() +
          ". Notably, the shop " +
          this.shopQuirksLong[this.shopQuirk].toLowerCase() +
          " and " +
          this.shopProtectionsLong[this.shopProtection].toLowerCase() +
          ".";

        let shopkeeperDescription =
        this.shopkeeperName +
        " is a " +
        this.shopkeeperRace.toLowerCase() +
        " who gave up life as a " +
        this.shopkeeperBackground.toLowerCase() +
        " to become a merchant. " +
        this.shopkeeperName +
        " " +
        this.shopkeeperAppearancesLong[this.shopkeeperAppearance].toLowerCase() +
        ", is " +
        this.shopkeeperPersonality1.toLowerCase() +
        " and " +
        this.shopkeeperPersonality2.toLowerCase() +
        ". They dislike " +
        this.shopkeeperBias1.toLowerCase() +
        " and has a soft spot for " +
        this.shopkeeperBias2.toLowerCase() +
        ".";

        let filteredItems = this.items.map(({ value, ...rest }) => ({
          ...rest,
          value: Number(value),
        }));
        if (this.$store.state.logged_in) {
          filteredItems = this.$store.state.customItems.concat(filteredItems);
        }
        const seen = new Set();
        filteredItems = filteredItems.filter((item) => {
          const duplicate = seen.has(item.name);
          seen.add(item.name);
          return !duplicate;
        });
        if (this.value1 != null && this.value1 != "") {
          filteredItems = filteredItems.filter(
            (item) => item.value >= this.value1
          );
        }
        if (this.value2 != null && this.value2 != "") {
          filteredItems = filteredItems.filter(
            (item) => item.value <= this.value2
          );
        }
        if (this.includedTypes && this.includedTypes.length > 0) {
          filteredItems = filteredItems.filter((item) =>
            this.includedTypes.includes(item.type)
          );
        }
        if (this.includedRarities && this.includedRarities.length > 0) {
          filteredItems = filteredItems.filter((item) =>
            this.includedRarities.includes(item.rarity)
          );
        }
        if (this.includedSources && this.includedSources.length > 0) {
          filteredItems = filteredItems.filter((item) =>
            this.includedSources.includes(item.source)
          );
        }

        const chooseRandom = (arr, num = 1) => {
          const res = [];
          for (let i = 0; i < num; ) {
            const random = Math.floor(Math.random() * arr.length);
            if (res.indexOf(arr[random]) !== -1) {
              continue;
            }
            res.push(arr[random]);
            i++;
          }
          return res;
        };
        let inventorySize = 1;
        if (this.numberOptionDice) {
          if (this.modifier == "+") {
            inventorySize =
              Math.abs(this.nItems) +
              Math.floor(Math.random() * parseInt(this.dice) + 1);
          } else
            inventorySize =
              -Math.abs(this.nItems) +
              Math.floor(Math.random() * parseInt(this.dice) + 1);
        } else {
          inventorySize = this.nItems;
        }
        inventorySize < 1 ? (inventorySize = 1) : "";
        const inventory = chooseRandom(filteredItems, inventorySize);
        this.$store
          .dispatch("saveShop", {
            name: this.name,
            inventory: inventory,
            description: shopDescription,
            shopkeeper: shopkeeperDescription,
          })
          .then((response) => {
            response
              ? this.$store.commit("selectShop", this.name)
              : console.log("potato");
          });
        this.$emit("close");
      }
    },
    onChange(event) {
      let preset = event.target.value;
      switch (preset) {
        case "Town":
          this.numberOptionDice = true;
          this.numberOptionFixed = false;
          this.dice = 4;
          this.modifier = "+";
          this.nItems = 1;
          this.includedRarities = ["Common", "Uncommon"];
          this.includedTypes = ["Armor", "Potion", "Weapon", "Wondrous Item"];
          this.value1 = 0;
          this.value2 = 1000;
          break;
        case "City":
          this.numberOptionDice = true;
          this.numberOptionFixed = false;
          this.dice = 6;
          this.modifier = "+";
          this.nItems = 2;
          this.includedRarities = ["Common", "Uncommon", "Rare"];
          this.includedTypes = [
            "Armor",
            "Potion",
            "Scroll",
            "Wand",
            "Weapon",
            "Wondrous Item",
          ];
          this.value1 = 0;
          this.value2 = 5000;
          break;
        case "Metropolis":
          this.numberOptionDice = true;
          this.numberOptionFixed = false;
          this.dice = 12;
          this.modifier = "+";
          this.nItems = 5;
          this.includedRarities = ["Common", "Uncommon", "Rare", "Very Rare"];
          this.includedTypes = [];
          this.value1 = 0;
          this.value2 = 20000;
          break;
        default:
          break;
      }
    },
    shuffleInventory() {
      let diceOptions = [4, 6, 8, 10, 12, 20]
      this.dice = diceOptions[Math.floor(Math.random() * diceOptions.length)];
      this.modifier = "+";
      this.nItems = Math.floor(Math.random() * 10);
      let nRarities = Math.floor(Math.random() * 4) + 1;
      nRarities == 1 ? this.includedRarities = ["Common"] : nRarities == 2 ? this.includedRarities = ["Common", "Uncommon"] : nRarities == 3 ? this.includedRarities = ["Common", "Uncommon", "Rare"] : nRarities == 4 ? this.includedRarities = ["Common", "Uncommon", "Rare", "Very Rare"] : this.includedRarities = [];
      this.includedTypes = [];
      this.value1 = 0;
      let highValues = [500, 1000, 2500, 5000, 10000, 15000, 25000, 50000, null]
      this.value2 = highValues[Math.floor(Math.random() * highValues.length)];
    },
    shuffleShop() {
      this.shopLocation =
        this.shopLocations[
          Math.floor(Math.random() * this.shopLocations.length)
        ];
      this.shopAppearance1 =
        this.shopAppearances1[
          Math.floor(Math.random() * this.shopAppearances1.length)
        ];
      this.shopAppearance2 =
        this.shopAppearances2[
          Math.floor(Math.random() * this.shopAppearances2.length)
        ];
      this.shopQuirk = Math.floor(Math.random() * this.shopQuirks.length);
      this.shopProtection = Math.floor(
        Math.random() * this.shopProtections.length
      );
    },
    shuffleShopkeeper() {
      this.shopkeeperName =
        this.shopkeeperNames[
          Math.floor(Math.random() * this.shopkeeperNames.length)
        ];
      let shopnamePrefix =
        this.shopnamePrefixes[
          Math.floor(Math.random() * this.shopnamePrefixes.length)
        ];
      let shopnameSuffix =
        this.shopnameSuffixes[
          Math.floor(Math.random() * this.shopnameSuffixes.length)
        ];
      this.name =
        this.shopkeeperName + "'s " + shopnamePrefix + " " + shopnameSuffix;
      this.shopkeeperRace =
        this.shopkeeperRaces[
          Math.floor(Math.random() * this.shopkeeperRaces.length)
        ];
      this.shopkeeperBackground =
        this.shopkeeperBackgrounds[
          Math.floor(Math.random() * this.shopkeeperBackgrounds.length)
        ];
      this.shopkeeperAppearance =
          Math.floor(Math.random() * this.shopkeeperAppearances.length)
        ;
      this.shopkeeperPersonality1 =
        this.shopkeeperPersonalities1[
          Math.floor(Math.random() * this.shopkeeperPersonalities1.length)
        ];
      this.shopkeeperPersonality2 =
        this.shopkeeperPersonalities2[
          Math.floor(Math.random() * this.shopkeeperPersonalities2.length)
        ];
      this.shopkeeperBias1 =
        this.shopkeeperBiases1[
          Math.floor(Math.random() * this.shopkeeperBiases1.length)
        ];
      this.shopkeeperBias2 =
        this.shopkeeperBiases2[
          Math.floor(Math.random() * this.shopkeeperBiases2.length)
        ];
      this.shopkeeperQuirk = Math.floor(
        Math.random() * this.shopkeeperQuirks.length
      );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.logged_in;
    },
    excludedTypes() {
      if (this.includedTypes && this.includedTypes.length > 0) {
        return this.types.filter((type) => !this.includedTypes.includes(type));
      } else {
        return this.types;
      }
    },
    excludedRarities() {
      if (this.includedRarities && this.includedRarities.length > 0) {
        return this.rarities.filter(
          (rarity) => !this.includedRarities.includes(rarity)
        );
      } else {
        return this.rarities;
      }
    },
    excludedSources() {
      if (this.includedSources && this.includedSources.length > 0) {
        return this.sources.filter(
          (source) => !this.includedSources.includes(source)
        );
      } else {
        return this.sources;
      }
    },
  },
};
</script>

<style scoped>
.menu {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: fixed;
  width:600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #282838;
  padding: 10px;
  border-radius: 5px;
  z-index: 101;
  user-select: none;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.486);
  transition: all 1s;
}

.menu-header {
  border-radius:5px;
  background:#0b0b16;
  cursor:pointer;
}

.menu-section {
  transition:all 0.5s ease;
}

h3,
h2 {
  color: white;
}

.save-button {
  background: white;
  color: #0b0b16;
}

.fields {
  justify-content: center;
}

.filter,
.selected-filters {
  width: 100%;
}

.field {
  flex-direction: row;
}

label {
  margin-right: 5px;
}

input {
  cursor: pointer;
}

.menu-row {
  margin-bottom: 10px;
}

.menu-row > div {
  margin-right: 10px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
}

.inventory-select {
  width: 100%;
}

.inventory-size {
  margin: 0;
}

.number-items {
  width: 100%;
  display: flex;
  flex-direction: row;
}


.number-items .text-input {
  margin-left:5px;
  width:33.3% !important;
}

.dice-options {
  display: flex;
  width: 66.6%;
}

.dice-select {
  margin-right: 5px;
  width: 60%;
  text-align: center;
}

.modifier-select {
  width:40%;
  text-align: center;
}

.number-options {
  display: flex;
}

.number-option {
  background: white;
  color: darkgreen;
  font-weight: bold;
  padding: 1px 5px;
  margin-bottom: 4px;
  margin-left: 10px;
  margin-right: 0px !important;
  border-radius: 5px;
  filter: opacity(0.3) grayscale(1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.number-option.highlight {
  filter: opacity(1) grayscale(0);
}

.modifier-select {
  font-weight: 600;
  font-size: 1.2rem;
}

.filter-random {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.filter-random i {
  font-size: 2rem;
  color: white;
  transition: all 0.3s ease;
}

.filter-random i:hover {
  color: darkgreen;
  transition: all 0.3s ease;
  transform: scale(1.25);
}

.number-inputs {
  align-items: center;
}

.positive {
  font-weight: 600;
  color: green;
}

.negative {
  color: red;
}

.number-items .text-input,
.number-inputs .text-input {
  width: 100%;
  min-height: 40px;
}

.drop-expand-enter-active,
.drop-expand-leave-active {
  transform-origin: top;
  transition: all 0.3s ease-in-out;
  overflow:hidden;
}

.drop-expand-enter-from,
.drop-expand-leave-to {
  max-height:0;
}
.drop-expand-enter-to,
.drop-expand-leave-from {
  max-height:1000px;
}


@media only screen and (max-width: 1080px) {
  .menu {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #0b0b16;
    position: fixed;
    z-index: 100;
    width: 90vw;
    transition: all 0.3s ease;
    opacity: 1;
  }

  h2 {
    margin-bottom: 5px;
  }

  .menu-row {
    margin-bottom: 0;
  }

  .menu-row > div {
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .fields {
    flex-direction: column;
    justify-content: space-around;
  }
  .filter {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .filter h3 {
    width: 30%;
  }

  .filter .text-input,
  .filter .selected-filters,
  .filter select,
  .number-inputs,
  .number-items {
    width: 70%;
  }

  .save-button {
    margin-top: 10px;
  }

  h3.dash {
    width: auto;
  }
  .inventory-size {
    flex-direction: column;
    width: 30%;
  }

  .number-options {
    display: flex;
  }

  .number-option {
    font-size: 0.8rem;
    margin-left: 0;
    margin-right: 5px !important;
  }

  .number-items .text-input {
    width: 100%;
    min-height: 40px;
  }
}
</style>
