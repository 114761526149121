<template>
  <div>
    <div class="buttons">
      <div
        class="fixed-button show-filters"
        v-if="$store.state.selectedShop == null"
        @click="
          $emit('showFilters'),
            (showShopDrop = false),
            (showSaveMenu = false),
            (showAddMenu = false),
            (showShopMenu = false)
        "
      >
        <i class="fas fa-filter"></i>
      </div>
      <div
        class="fixed-button show-shop"
        @mouseover="showShopTooltip = true"
        @mouseleave="showShopTooltip = false"
        @click="
          (showShopDrop = !showShopDrop),
            (showSaveMenu = false),
            (showAddMenu = false),
            $emit('closeFilters')
        "
      >
        <i class="fas fa-store"> </i>
        <transition name="fade"
          ><div class="tooltip" v-if="showShopTooltip && !showShopDrop">
            Custom Shop
          </div></transition
        >
      </div>
      <div
        @mouseover="showSaveTooltip = true"
        @mouseleave="showSaveTooltip = false"
        @click="
          (showSaveMenu = true),
            (showAddMenu = false),
            (showShopMenu = false),
            (showShopDrop = false),
            $emit('closeFilters')
        "
        class="fixed-button download-csv"
      >
        <i class="fas fa-save"></i>
        <transition name="fade"
          ><div class="tooltip" v-if="showSaveTooltip">
            Save Price List as CSV
          </div></transition
        >
      </div>
      <div
        class="fixed-button add-item"
        @click="
          (showAddMenu = true),
            (showSaveMenu = false),
            (showShopMenu = false),
            (showShopDrop = false),
            $emit('closeFilters')
        "
        @mouseover="showAddTooltip = true"
        @mouseleave="showAddTooltip = false"
      >
        <i class="fas fa-plus"></i>
        <transition name="fade"
          ><div class="tooltip" v-if="showAddTooltip">
            Add Custom Item
          </div></transition
        >
      </div>
    </div>
    <transition name="fade">
      <SaveMenu
        v-if="showSaveMenu"
        @close="showSaveMenu = false"
        :filteredItems="filteredItems"
      ></SaveMenu>
    </transition>
    <transition name="fade">
      <AddMenu v-if="showAddMenu" @close="showAddMenu = false"></AddMenu>
    </transition>
    <transition name="fade">
      <ShopMenu v-if="showShopMenu" @close="showShopMenu = false"></ShopMenu>
    </transition>
    <transition name="fade">
      <ShopDropList
        @close="showShopDrop = false"
        v-if="showShopDrop && !showShopMenu"
        @showShopMenu="showShopMenu = true"
      ></ShopDropList>
    </transition>
  </div>
</template>

<script>
import ShopMenu from "./ShopMenu.vue";
import SaveMenu from "./SaveMenu.vue";
import AddMenu from "./AddMenu.vue";
import ShopDropList from "./ShopDropList.vue";

export default {
  name: "PriceListButtons",
  components: { ShopMenu, SaveMenu, AddMenu, ShopDropList },
  props: ["filteredItems"],
  emits: ["showFilters", "closeFilters"],
  data() {
    return {
      showShopMenu: false,
      showShopDrop: false,
      showShopTooltip: false,
      showAddMenu: false,
      showAddTooltip: false,
      showSaveMenu: false,
      showSaveTooltip: false,
      showDeleteMenu: false,
      shopToDelete: null,
    };
  },
};
</script>

<style scoped>
.fixed-button {
  font-size: 2rem;
  font-weight: 600;
}

.fixed-button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.buttons {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 10px;
  right: 2vw;
  z-index: 101;
}

.fixed-button {
  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background: linear-gradient(90deg, #2f2f3d, #0b0b16);
  color: white;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin: 0px 1vw;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

.show-filters,
.show-shop {
  display: none;
}

@media only screen and (max-width: 600px) {
  .buttons {
    width: 100vw;
    right: 0;
  }

  .show-filters,
  .show-shop {
    display: flex;
  }
}
</style>
