<template>
  <div class="container">
    <div class="overlay" @click="$emit('close')"></div>
    <div class="menu">
      <div class="menu-row" v-if="loggedIn"><h3> Add Item to Price List</h3></div>
      <div class="menu-row fields" v-if="loggedIn">
        <i class="fas fa-times close" @click="$emit('close')"></i>
        <div class="name">
          <h4>Name:</h4>
          <input
            class="text-input"
            v-model="name"
            placeholder="Magic Item Name"
          />
        </div>
        <div class="type">
          <h4>Type:</h4>
          <select id="type" v-model="type">
            <option value="Armor">Armor</option>
            <option value="Potion">Potion</option>
            <option value="Ring">Ring</option>
            <option value="Rod">Rod</option>
            <option value="Scroll">Scroll</option>
            <option value="Staff">Staff</option>
            <option value="Wand">Wand</option>
            <option value="Weapon">Weapon</option>
            <option value="Wondrous Item">Wondrous Item</option>
          </select>
        </div>
        <div class="subtype">
          <h4>Subtype:</h4>
          <input class="text-input" v-model="subtype" />
        </div>
        <div class="value">
          <h4>Value:</h4>
          <input class="text-input" type="number" v-model="value" />
        </div>
        <div class="subvalue">
          <h4>Value Note:</h4>
          <input class="text-input" v-model="subvalue" />
        </div>
      </div>
      <div class="menu-row">
        <div class="warning" v-if="showNonameWarning">
          Please input a name.
        </div>
        <div v-if="showDuplicateWarning" class="warning">
          An item already exists with that name.
        </div>
                <div v-if="!loggedIn" class="warning">
          You must be logged in to add custom items to the database.
        </div>
      </div>
      <div class="menu-row">
        <div class="save-button" @click="saveItem"  v-if="loggedIn">Add Item</div>
        <div class="save-button" @click="goToLogin"  v-if="!loggedIn">Login/Signup</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddMenu",
  emits: ["close"],
  data() {
    return {
      name: "",
      type: "Armor",
      subtype: "Any",
      rarity: "Common",
      subvalue: "",
      value: 1,
      showDuplicateWarning: false,
      showNonameWarning: false
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.logged_in
    }
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    saveItem() {
      if (!this.name || this.name.length == 0) {
        this.showNonameWarning = true;
        this.showDuplicateWarning = false;
      } else if (
        this.$store.state.customItems.some((item) => item.name == this.name)
      ) {
        this.showNonameWarning = false;
        this.showDuplicateWarning = true;
      } else {
        const newItem = {
          name: this.name,
          type: this.type,
          subtype: this.subtype.length > 0 ? "(" + this.subtype + ")" : "",
          rarity: this.rarity,
          value: this.value,
          subvalue: this.subvalue,
          source: "Custom",
        };
        this.$store.dispatch("saveItem", newItem);
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.menu {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: fixed;
  width:1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #282838;
  padding: 10px;
  border-radius: 5px;
  z-index: 101;
  user-select: none;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.486);
  transition: all 1s;
}
h4, h3,
h2 {
  color: white;
}

.save-button {
  background: white;
  color: #0b0b16;
}


.menu-row {
  margin-bottom:10px;
}

.menu-row > div {
  margin-right: 10px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  color:white;
}

@media only screen and (max-width: 1000px) {
  .menu {
    width:80vw;
    font-size:1.2rem;
  }

.menu-row > div {
  margin-right: 0px;
  margin-bottom:5px;
}

  .fields {
  flex-direction:column;
  }

  .fields > div {
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
  }

  input, select {
    width:60%;
  }
}
</style>
