import { createApp } from "vue";
import App from "./App.vue";
import store from "./store.js";
import "./firebase/firebaseInit.js";
import firebase from "firebase";
import Pricelist from "./components/PriceList.vue"
import LoginPage from "./components/LoginPage.vue"
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Pricelist,
    },
    { path: "/login", component: LoginPage },
  ],
});


firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app')
