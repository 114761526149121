import { createStore } from "vuex";
import firebase from "firebase";
import dmg from "./components/json/dmg.json";
import tcoe from "./components/json/tcoe.json";
import xgte from "./components/json/xgte.json";
import wgmm from "./components/json/wgmm.json";

const store = createStore({
  state() {
    return {
      user: "",
      logged_in: false,
      customItems: [],
      customShops: [],
      selectedShop: null,
      items: dmg.concat(tcoe).concat(xgte).concat(wgmm),
    };
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_LOGGED_IN(state, status) {
      state.logged_in = status;
      if (!status) {
        state.customItems = [];
      }
    },
    selectShop(state, shopName) {
      state.selectedShop = state.customShops.find(
        (shop) => shop.name == shopName
      );
    },
  },
  actions: {
    deleteItem(context, item) {
      const customItems = context.state.customItems.filter(
        (x) => x.name !== item
      );
      firebase
        .database()
        .ref("users/" + context.state.user.uid + "/customItems")
        .set(customItems);
      context.state.customItems = customItems;
    },
    deleteShop(context, shopName) {
      console.log(shopName);
      const customShops = context.state.customShops.filter(
        (x) => x.name !== shopName
      );
      context.state.selectedShop && context.state.selectedShop.name == shopName
        ? (context.state.selectedShop = null)
        : "";
      firebase
        .database()
        .ref("users/" + context.state.user.uid + "/customShops")
        .set(customShops);
      context.state.customShops = customShops;
    },
    fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          email: user.email,
          uid: user.uid,
        });
        context.dispatch("loadItems");
        context.dispatch("loadShops");
      }
    },
    loadItems(context) {
      if (firebase.auth().currentUser) {
        const itemsRef = firebase
          .database()
          .ref("users/" + context.state.user.uid + "/customItems/");
        itemsRef.on("value", (snapshot) => {
          var items = [];
          snapshot.forEach((item) => {
            items.push(item.val());
            context.state.customItems = items;
          });
        });
      }
    },
    loadShops(context) {
      if (firebase.auth().currentUser) {
        const shopsRef = firebase
          .database()
          .ref("users/" + context.state.user.uid + "/customShops/");
        shopsRef.on("value", (snapshot) => {
          var shops = [];
          snapshot.forEach((shop) => {
            shops.push(shop.val());
            context.state.customShops = shops.sort((a, b) =>
              a.name < b.name ? -1 : b.name > a.name ? 1 : 0
            );
          });
        });
      }
    },
    saveItem(context, newItem) {
      if (context.state.customItems.some((item) => item.name == newItem.name)) {
        context.state.customItems.unshift(newItem);
        const seen = new Set();
        const customItems = context.state.customItems.filter((item) => {
          const duplicate = seen.has(item.name);
          seen.add(item.name);
          return !duplicate;
        });
        context.state.customItems = customItems;

        if (firebase.auth().currentUser) {
          firebase
            .database()
            .ref("users/" + context.state.user.uid + "/customItems")
            .set(customItems);
        }
      } else {
        if (firebase.auth().currentUser) {
          firebase
            .database()
            .ref("users/" + context.state.user.uid + "/customItems")
            .push(newItem)
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        }
      }
    },
    editShopName(context, { oldName, newName }) {
      let shop = context.state.customShops.find((shop) => shop.name == oldName);
      shop.name = newName;
      context.state.selectedShop = shop;
      context.dispatch("saveShop", shop);
    },
    editShopDescription(context, { oldDescription, newDescription }) {
      let shop = context.state.customShops.find(
        (shop) => shop.description == oldDescription
      );
      shop.description = newDescription;
      context.state.selectedShop = shop;
      context.dispatch("saveShop", shop);
    },
    editShopkeeperDescription(context, { oldDescription, newDescription }) {
      let shop = context.state.customShops.find(
        (shop) => shop.shopkeeper == oldDescription
      );
      shop.shopkeeper = newDescription;
      context.state.selectedShop = shop;
      context.dispatch("saveShop", shop);
    },
    addToShop(context, { item, shopName }) {
      let shop = context.state.customShops.find(
        (shop) => shop.name == shopName
      );
      shop.inventory.push(item);
      context.dispatch("saveShop", shop);
    },
    removeFromShop(context, itemName) {
      // let shop = context.state.customShops.find(shop => shop.name == context.state.selectedShop.name)
      // shop.inventory = shop.inventory.filter((item) => item.name != itemName)
      context.state.selectedShop.inventory =
        context.state.selectedShop.inventory.filter(
          (item) => item.name != itemName
        );
      let shop = context.state.customShops.find(
        (shop) => shop.name == context.state.selectedShop.name
      );
      shop = context.state.selectedShop;
      context.dispatch("saveShop", shop);
    },
    async saveShop(context, newShop) {
      if (context.state.customShops.some((shop) => shop.name == newShop.name)) {
        context.state.customShops.unshift(newShop);
        const seen = new Set();
        let customShops = context.state.customShops.filter((shop) => {
          const duplicate = seen.has(shop.name);
          seen.add(shop.name);
          return !duplicate;
        });
        customShops = customShops.sort((a, b) =>
          a.name > b.name ? -1 : b.name < a.name ? 1 : 0
        );
        context.state.customShops = customShops;
        if (firebase.auth().currentUser) {
          firebase
            .database()
            .ref("users/" + context.state.user.uid + "/customShops")
            .set(customShops);
        }
      } else {
        if (firebase.auth().currentUser) {
          firebase
            .database()
            .ref("users/" + context.state.user.uid + "/customShops")
            .push(newShop)
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        }
      }
      return true;
    },
  },
});

export default store;
