<template>
  <div>
    <BaseHeader></BaseHeader>

    <!-- OVERLAY WHICH IS CLICKABLE WHEN FILTERS ARE OPEN TO CLOSE THEM -->

    <div class="overlay" v-if="showFilters" @click="showFilters = false"></div>

    <!-- MAIN CONTAINER FOR THIS VIEW -->

    <div class="pricelist-container">
      <!-- STICKY BUTTONS AT BOTTOM OF SCREEN -->

      <PriceListButtons
        :filteredItems="filteredItems"
        @showFilters="showFilters = !showFilters"
        @closeFilters="showFilters = false"
      ></PriceListButtons>

      <transition name="topbar" mode="out-in">
        <ShopTop v-if="this.$store.state.selectedShop != null"></ShopTop>

        <!-- THE FILTER BAR AT THE TOP. PRESENT WHILE IN ITEM LIST VIEW -->

        <div :class="{ show: showFilters }" class="filter-container" v-else>
          <div class="filter">
            <h3>Item</h3>
            <input
              class="text-input"
              type="text"
              v-model="search"
              autocomplete="off"
              list="autocompleteOff"
              placeholder="Search for Item Name"
            />
          </div>

          <!-- TYPE FILTER -->

          <div class="filter" @mouseleave="showTypeFilters = false">
            <h3>Type</h3>
            <div class="selected-filters" @mouseover="showTypeFilters = true">
              <div class="placeholder" v-if="!includedTypes || includedTypes.length == 0">All</div>
              <div
                @click="filterType(type)"
                class="filter-element"
                v-for="type in includedTypes"
                v-bind:key="type"
              > 
                <i class="fas fa-times"></i>{{ type }}
              </div>
              <transition name="fade" mode="out-in">
                <div
                  class="filter-options"
                  v-if="showTypeFilters && excludedTypes.length > 0"
                >
                  <div
                    v-for="type in excludedTypes"
                    v-bind:key="type"
                    @click="filterType(type)"
                    class="filter-element"
                    :class="{ filtered: includedTypes.includes(type) }"
                  >
                    {{ type }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="filter" @mouseleave="showRarityFilters = false">
            <h3>Rarity</h3>
            <div class="selected-filters" @mouseover="showRarityFilters = true">
              <div class="placeholder" v-if="!includedRarities || includedRarities.length == 0">All</div>
              <div
                @click="filterRarity(rarity)"
                class="filter-element"
                v-for="rarity in includedRarities"
                v-bind:key="rarity"
              >
                <i class="fas fa-times"></i>{{ rarity }}
              </div>
              <transition name="fade" mode="out-in">
                <div
                  class="filter-options"
                  v-if="showRarityFilters & (excludedRarities.length > 0)"
                >
                  <div
                    v-for="rarity in excludedRarities"
                    v-bind:key="rarity"
                    @click="filterRarity(rarity)"
                    class="filter-element"
                    :class="{ filtered: includedRarities.includes(rarity) }"
                  >
                    {{ rarity }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="filter" @mouseleave="showAttunementFilters = false">
            <h3>Attunement</h3>
            <div
              class="selected-filters"
              @mouseover="showAttunementFilters = true"
            >
              <div class="placeholder" v-if="!includedAttunements || includedAttunements.length == 0">Any</div>
              <div
                @click="filterAttunement(attunement)"
                class="filter-element"
                v-for="attunement in includedAttunements"
                v-bind:key="attunement"
              >
                <i class="fas fa-times"></i>{{ attunement }}
              </div>
              <transition name="fade" mode="out-in">
                <div
                  class="filter-options"
                  v-if="
                    showAttunementFilters & (excludedAttunements.length > 0)
                  "
                >
                  <div
                    v-for="attunement in excludedAttunements"
                    v-bind:key="attunement"
                    @click="filterAttunement(attunement)"
                    class="filter-element"
                    :class="{
                      filtered: includedAttunements.includes(attunement),
                    }"
                  >
                    {{ attunement }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="filter">
            <h3>Value</h3>
            <div class="menu-row centered number-inputs">
              <input
                type="number"
                class="number-input"
                v-model="value1"
                placeholder="0"
              />–
              <input
                type="number"
                class="number-input"
                v-model="value2"
                placeholder="Any"
              />
            </div>
          </div>
          <div class="filter" @mouseleave="showSourceFilters = false">
            <div class="source-header">
              <h3>Source</h3>
              <div class="show-source">
                <p>Show Source?</p>
                <input
                  v-model="showSource"
                  type="checkbox"
                  id="source"
                  name="source"
                  value="Yes"
                />
              </div>
            </div>
            <div class="selected-filters" @mouseover="showSourceFilters = true">
              <div class="placeholder" v-if="!includedSources || includedSources.length == 0">All</div>
              <div
                v-for="source in includedSources"
                v-bind:key="source"
                @click="filterSources(source)"
                class="filter-element"
              >
                <i class="fas fa-times"></i>{{ source }}
              </div>
              <transition name="fade" mode="out-in">
                <div
                  class="filter-options"
                  v-if="showSourceFilters & (excludedSources.length > 0)"
                >
                  <div
                    v-for="source in excludedSources"
                    v-bind:key="source"
                    @click="filterSources(source)"
                    class="filter-element"
                    :class="{ filtered: includedSources.includes(source) }"
                  >
                    {{ source }}
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </transition>

      <!-- FILTER BAR AT THE TOP END -->

      <!-- TABLE OF ITEMS -->

      <div class="item header" v-if="filteredItems && filteredItems.length > 0">
        <div class="item-name">
          Name
          <i
            class="fas fa-caret-down"
            @click="
              sortBy == 'nameASC' ? (sortBy = 'nameDESC') : (sortBy = 'nameASC')
            "
            :class="{
              highlight: sortBy == 'nameASC' || sortBy == 'nameDESC',
              'fa-caret-up': sortBy == 'nameDESC',
            }"
          ></i>
        </div>
        <div class="item-value" :class="{ extended: !showSource }">
          Value
          <i
            class="fas fa-caret-down"
            @click="
              sortBy == 'valueASC'
                ? (sortBy = 'valueDESC')
                : (sortBy = 'valueASC')
            "
            :class="{
              'fa-caret-up': sortBy == 'valueDESC',
              highlight: sortBy == 'valueASC' || sortBy == 'valueDESC',
            }"
          ></i>
        </div>
        <div class="item-source" v-if="showSource">
          SRC
          <i
            class="fas fa-caret-down"
            @click="
              sortBy == 'srcASC' ? (sortBy = 'srcDESC') : (sortBy = 'srcASC')
            "
            :class="{
              highlight: sortBy == 'srcASC' || sortBy == 'srcDESC',
              'fa-caret-up': sortBy == 'srcDESC',
            }"
          ></i>
        </div>
      </div>

      <div class="filter-warning" v-else>
        Can't find any matches. Maybe your filters are too strict?
        <div class="reset" @click="resetFilters">Reset filters</div>
      </div>
      <div class="item" v-for="item in filteredItems" v-bind:key="item.name">
        <div
          class="item-name"
          :class="{
            common: item.rarity == 'Common',
            uncommon: item.rarity == 'Uncommon',
            rare: item.rarity == 'Rare',
            'very-rare': item.rarity == 'Very Rare',
            legendary: item.rarity == 'Legendary',
            artifact: item.rarity == 'Artifact',
          }"
          @mouseleave="showAddShopMenu = null"
        >
          <a v-bind:href="item.url" target="_blank">{{ item.name }}</a
          ><i
            v-if="item.attunement && item.attunement.length > 1"
            class="fab fa-adn"
          ></i>
          <i
            class="fas fa-trash"
            v-if="item.source == 'Custom'"
            @click="deleteItem(item.name)"
          ></i>
          <i
            class="fas fa-edit"
            @click="editing == item.name ? (editing = null) : edit(item.name)"
            v-if="loggedIn"
          ></i>
          <i
            class="fas fa-plus"
            v-if="loggedIn && $store.state.selectedShop == null"
            @click="
              showAddShopMenu == item.name
                ? (showAddShopMenu = null)
                : (showAddShopMenu = item.name)
            "
            ><transition name="fade"
              ><div class="add-shop-menu" v-if="showAddShopMenu == item.name">
                <h3>Add to Shop</h3>
                <div
                  v-for="shop in $store.state.customShops"
                  v-bind:key="shop.name"
                  @click="addToShop(item, shop.name)"
                >
                  {{ shop.name }}
                </div>
              </div></transition
            ></i
          >
          <i
            class="fas fa-times"
            v-if="$store.state.selectedShop != null"
            @click="$store.dispatch('removeFromShop', item.name)"
          ></i>

          <div v-if="editing == item.name">
            <input
              class="input-type"
              type="text"
              placeholder="Item Type"
              v-model="editingType"
              @keyup.enter="
                changeItem(
                  item.name,
                  editingType,
                  editingSubtype,
                  editingRarity,
                  editingValue,
                  editingSubvalue,
                  editingAttunement
                ),
                  (editing = null)
              "
            /><input
              class="input-subtype"
              type="text"
              placeholder="Item Subtype"
              v-model="editingSubtype"
              @keyup.enter="
                changeItem(
                  item.name,
                  editingType,
                  editingSubtype,
                  editingRarity,
                  editingValue,
                  editingSubvalue,
                  editingAttunement
                ),
                  (editing = null)
              "
            /><input
              class="input-rarity"
              v-if="editing == item.name"
              type="text"
              v-model="editingRarity"
              placeholder="Rarity"
              @keyup.enter="
                changeItem(
                  item.name,
                  editingType,
                  editingSubtype,
                  editingRarity,
                  editingValue,
                  editingSubvalue,
                  editingAttunement
                ),
                  (editing = null)
              "
            /><label class="attunement-label" for="attunement"
              >Attunement:</label
            ><input
              class="input-attunement"
              v-if="editing == item.name"
              type="checkbox"
              v-model="editingAttunement"
              id="attunement"
              name="attunement"
              value="attunement"
              @click="
                changeItem(
                  item.name,
                  editingType,
                  editingSubtype,
                  editingRarity,
                  editingValue,
                  editingSubvalue,
                  editingAttunement
                )
              "
            />
          </div>
          <div v-else class="item-subheader">
            {{ item.type }}{{ item.subtype ? " " + item.subtype : "" }},
            {{ item.rarity }}
          </div>
        </div>
        <div class="item-value" :class="{ extended: !showSource }">
          <div v-if="editing == item.name">
            <input
              class="input-value"
              type="number"
              v-model="editingValue"
              :placeholder="item.value"
              @keyup.enter="
                changeItem(
                  item.name,
                  editingType,
                  editingSubtype,
                  editingRarity,
                  editingValue,
                  editingSubvalue,
                  editingAttunement
                ),
                  (editing = null)
              "
            />
            gp
          </div>
          <div v-else>
            {{ item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}{{ item.subvalue ? "+" : "" }} gp
          </div>
          <input
            class="input-subvalue"
            v-if="editing == item.name"
            type="text"
            v-model="editingSubvalue"
            placeholder="Value Note"
            @keyup.enter="
              changeItem(
                item.name,
                editingType,
                editingSubtype,
                editingRarity,
                editingValue,
                editingSubvalue,
                editingAttunement
              ),
                (editing = null)
            "
          />
          <div v-else class="item-subvalue">{{ item.subvalue }}</div>
        </div>
        <div class="item-source" v-if="showSource">{{ item.source }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSlider from "vue-slider-component";
// import "vue-slider-component/theme/antd.css";
import BaseHeader from "./BaseHeader.vue";
import ShopTop from "./ShopTop.vue";
import PriceListButtons from "./PriceListButtons.vue";

export default {
  name: "PriceList",
  components: { BaseHeader, ShopTop, PriceListButtons },
  data() {
    return {
      showFilters: false,
      showTypeFilters: false,
      showRarityFilters: false,
      showSourceFilters: false,
      showAttunementFilters: false,
      showAddMenu: false,
      showSource: false,
      showSaveMenu: false,
      showOverlay: false,
      showAddTooltip: false,
      showAddShopMenu: false,
      showSaveTooltip: false,
      showShopTooltip: false,
      showShopMenu: false,
      showShopDrop: false,
      showShopsList: false,
      showItemMenu: null,
      editing: null,
      editingType: "",
      editingSubtype: "",
      editingRarity: "",
      editingValue: "",
      editingSubvalue: "",
      editingAttunement: "",
      value1: null,
      value2: null,
      includedValues: [0, 50000],
      items: this.$store.state.items,
      valueFormat: (v) =>
        `${("" + v)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .replace(/50,000/g, "50,000+")} gp`,
      search: "",
      sortBy: "nameASC",
      sources: ["DMG", "WGMM", "XGTE", "TCOE", "Custom"],
      rarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
      types: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      attunements: ["Required", "Not Required"],
      includedRarities: [],
      includedTypes: [],
      includedSources: [],
      includedAttunements: [],
    };
  },
  computed: {
    excludedTypes() {
      if (this.includedTypes && this.includedTypes.length > 0) {
        return this.types.filter((type) => !this.includedTypes.includes(type));
      } else {
        return this.types;
      }
    },
    excludedRarities() {
      if (this.includedRarities && this.includedRarities.length > 0) {
        return this.rarities.filter(
          (rarity) => !this.includedRarities.includes(rarity)
        );
      } else {
        return this.rarities;
      }
    },
    excludedAttunements() {
      if (this.includedAttunements && this.includedAttunements.length > 0) {
        return this.attunements.filter(
          (attunement) => !this.includedAttunements.includes(attunement)
        );
      } else {
        return this.attunements;
      }
    },
    excludedSources() {
      if (this.includedSources && this.includedSources.length > 0) {
        return this.sources.filter(
          (source) => !this.includedSources.includes(source)
        );
      } else {
        return this.sources;
      }
    },
    filteredItems() {
      let filteredItems = this.items.map(({ value, ...rest }) => ({
        ...rest,
        value: Number(value),
      }));
      if (this.$store.state.logged_in) {
        filteredItems = this.$store.state.customItems.concat(filteredItems);
      }
      const seen = new Set();
      filteredItems = filteredItems.filter((item) => {
        const duplicate = seen.has(item.name);
        seen.add(item.name);
        return !duplicate;
      });
      if (this.$store.state.selectedShop != null) {
        const inventoryNames = this.$store.state.selectedShop.inventory.map(
          ({ name }) => {
            return name;
          }
        );
        filteredItems = filteredItems.filter((item) =>
          inventoryNames.includes(item.name)
        );
      }
      if (this.search && this.search.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.value1 != null && this.value1 != "") {
        filteredItems = filteredItems.filter(
          (item) => item.value >= this.value1
        );
      }
      if (this.value2 != null && this.value2 != "") {
        filteredItems = filteredItems.filter(
          (item) => item.value <= this.value2
        );
      }
      if (this.includedTypes && this.includedTypes.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          this.includedTypes.includes(item.type)
        );
      }
      if (this.includedRarities && this.includedRarities.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          this.includedRarities.includes(item.rarity)
        );
      }
      if (this.includedSources && this.includedSources.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          this.includedSources.includes(item.source)
        );
      }
      if (this.includedAttunements && this.includedAttunements.length == 1) {
        this.includedAttunements.includes("Required")
          ? (filteredItems = filteredItems.filter(
              (item) => item.attunement && item.attunement.length > 1
            ))
          : (filteredItems = filteredItems.filter(
              (item) => !item.attunement || item.attunement.length < 2
            ));
      }
      switch (this.sortBy) {
        case "nameASC":
          filteredItems = filteredItems.sort((a, b) =>
            a.name < b.name ? -1 : b.name > a.name ? 1 : 0
          );
          break;
        case "nameDESC":
          filteredItems = filteredItems.sort((a, b) =>
            a.name > b.name ? -1 : b.name < a.name ? 1 : 0
          );
          break;
        case "valueASC":
          filteredItems = filteredItems.sort((a, b) =>
            isNaN(a.value) ? -1 : a.value - b.value
          );
          break;
        case "valueDESC":
          filteredItems = filteredItems.sort((a, b) =>
            isNaN(b.value) ? -1 : b.value - a.value
          );
          break;
        case "typeASC":
          filteredItems = filteredItems.sort((a, b) =>
            a.type < b.type ? -1 : b.type > a.type ? 1 : 0
          );
          break;
        case "typeDESC":
          filteredItems = filteredItems.sort((a, b) =>
            a.type > b.type ? -1 : b.type < a.type ? 1 : 0
          );
          break;
        case "rarityASC":
          filteredItems = filteredItems.sort((a, b) =>
            this.rarities.indexOf(a.rarity) < this.rarities.indexOf(b.rarity)
              ? -1
              : this.rarities.indexOf(a.rarity) >
                this.rarities.indexOf(b.rarity)
              ? 1
              : 0
          );
          break;
        case "rarityDESC":
          filteredItems = filteredItems.sort((a, b) =>
            this.rarities.indexOf(a.rarity) > this.rarities.indexOf(b.rarity)
              ? -1
              : this.rarities.indexOf(a.rarity) <
                this.rarities.indexOf(b.rarity)
              ? 1
              : 0
          );
          break;
        case "srcASC":
          filteredItems = filteredItems.sort((a, b) =>
            a.source > b.source ? -1 : b.source < a.source ? 1 : 0
          );
          break;
        case "srcDESC":
          filteredItems = filteredItems.sort((a, b) =>
            a.source < b.source ? -1 : b.source > a.source ? 1 : 0
          );
          break;
        default:
          "";
      }
      return filteredItems;
    },
    loggedIn() {
      return this.$store.state.logged_in;
    },
  },
  methods: {
    addToShop(item, shopName) {
      this.$store.dispatch("addToShop", { item: item, shopName: shopName });
    },
    changeItem(
      itemName,
      newType,
      newSubtype,
      newRarity,
      newValue,
      newSubvalue,
      newAttunement
    ) {
      var changedItem = this.filteredItems.find(
        (item) => item.name == itemName
      );
      if (
        changedItem.type != newType ||
        changedItem.subtype != newSubtype ||
        changedItem.rarity != newRarity ||
        changedItem.value != newValue ||
        changedItem.subvalue != newSubvalue
      ) {
        newType == undefined
          ? (changedItem.type = "Any")
          : (changedItem.type = newType);
        newSubtype == undefined
          ? (changedItem.subtype = "")
          : (changedItem.subtype = newSubtype);
        newRarity == undefined
          ? (changedItem.rarity = "Varies")
          : (changedItem.rarity = newRarity);
        newValue == undefined
          ? (changedItem.value = 0)
          : (changedItem.value = newValue);
        newSubvalue == undefined
          ? (changedItem.subvalue = "")
          : (changedItem.subvalue = newSubvalue);
        newAttunement == true
          ? (changedItem.attunement = "Requires Attunement")
          : (changedItem.attunement = "");
        changedItem.source = "Custom";
        this.$store.dispatch("saveItem", changedItem);
      }
    },
    edit(itemName) {
      this.editing = itemName;
      this.editingType = this.filteredItems.find(
        (item) => item.name == itemName
      ).type;
      this.editingSubtype = this.filteredItems.find(
        (item) => item.name == itemName
      ).subtype;
      this.editingRarity = this.filteredItems.find(
        (item) => item.name == itemName
      ).rarity;
      this.editingValue = this.filteredItems.find(
        (item) => item.name == itemName
      ).value;
      this.editingSubvalue = this.filteredItems.find(
        (item) => item.name == itemName
      ).subvalue;
      this.filteredItems.find((item) => item.name == itemName).attunement &&
      this.filteredItems.find((item) => item.name == itemName).attunement
        .length > 1
        ? (this.editingAttunement = true)
        : (this.editingAttunement = false);
    },
    deleteItem(name) {
      this.$store.dispatch("deleteItem", name);
    },
    resetFilters() {
      this.includedSources = [];
      this.includedRarities = [];
      this.includedTypes = [];
      this.search = "";
      this.value1 = null;
      this.value2 = null;
    },
    filterType(type) {
      if (!this.includedTypes.includes(type)) {
        this.includedTypes.push(type);
      } else {
        this.includedTypes = this.includedTypes.filter(
          (filteredType) => filteredType != type
        );
      }
    },
    filterAttunement(attunement) {
      if (!this.includedAttunements.includes(attunement)) {
        this.includedAttunements.push(attunement);
      } else {
        this.includedAttunements = this.includedAttunements.filter(
          (filteredAttunement) => filteredAttunement != attunement
        );
      }
    },
    filterRarity(rarity) {
      if (!this.includedRarities.includes(rarity)) {
        this.includedRarities.push(rarity);
      } else {
        this.includedRarities = this.includedRarities.filter(
          (filteredRarity) => filteredRarity != rarity
        );
      }
    },
    filterSources(source) {
      if (!this.includedSources.includes(source)) {
        this.includedSources.push(source);
      } else {
        this.includedSources = this.includedSources.filter(
          (filteredSource) => filteredSource != source
        );
      }
    },
  },
};
</script>

<style>
.pricelist-container {
  width: 95%;
  max-width: 1200px;
  margin: 1vh auto 100px auto;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease;
  margin: 15px 0px;
  padding: 5px 10px;
  border-radius: 5px;
  background: rgb(222, 231, 235);
}

.selected-filters {
  display: flex;
  flex-flow: row wrap;
  min-height: 40px;
  transition: all 0.2s ease;
  border-radius: 5px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  position: relative;
  padding: 4px;
  cursor: pointer;
}

.filter {
  display: flex;
  flex-direction: column;
  width: 18%;
  margin: 5px;
  position: relative;
}

.filter-options {
  display: flex;
  flex-flow: row wrap;
  transition: all 0.2s ease;
  position: absolute;
  top: 100%;
  margin-left: -5px;
  padding: 5px;
  background: white;
  z-index: 2;
  width: calc(100%-5px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.144);
}

.filter-element {
  padding: 3px;
  margin: 0px 3px 3px 0px;
  border-radius: 5px;
  background: white;
  border: 1px solid black;
  cursor: pointer;
  font-size: 0.8rem;
  max-height: calc(0.8rem + 6px);
  user-select: none;
}

.item {
  padding: 5px;
  display: flex;
}

.item:nth-of-type(odd) {
  background: rgb(235, 238, 238);
}

.header {
  background: linear-gradient(90deg, #2f2f3d, #0b0b16) !important;
  color: white;
  border-radius: 5px;
  position: relative;
  font-weight: 600;
  align-items: center;
}

.filter-drop {
  position: absolute;
  background: #ffffff;
  top: 100%;
  -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-weight: 400;
  user-select: none;
}

i {
  cursor: pointer;
  padding: 5px;
}

.filter-drop div:hover {
  background: black;
  color: white;
}

.item-name {
  width: 60%;
  font-weight: 600;
}

.item-subheader {
  padding: 0px;
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
}

.item-source {
  position: relative;
  width: 10%;
  text-align: right;
}

.item-value {
  position: relative;
  text-align: right;
  width: 30%;
}

.item-value.extended {
  width: 40%;
}

.item-subvalue {
  font-size: 0.8rem;
  font-style: italic;
}

.input-type {
  width: 100px;
}

.input-subtype {
  width: 100px;
}

.input-rarity {
  width: 100px;
}

.input-value {
  width: 30%;
}

.input-subvalue {
  width: 90%;
}

.filtered {
  background: black;
  color: white;
}

.add-shop {
  font-size: 0.8rem;
}

.item .fas,
.item-name .fas,
.item-name .add-shop {
  opacity: 0;
  color: black;
  transition: all 0.3s ease;
  position: relative;
}

.item:hover .fas,
.item-name:hover .fas,
.item-name:hover .add-shop {
  transition: all 0.3s ease;
  opacity: 1;
}

.header .fas {
  color: white;
  opacity: 1;
}

.filter-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1.2rem;
}

.reset {
  color: darkred;
  font-size: 1.3rem;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 600;
}

.source-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.show-source {
  display: flex;
  flex-direction: row;
}

.source-header p {
  font-size: 0.6rem;
  margin-right: 5px;
  text-align: end;
}

.highlight {
  color: rgb(0, 253, 169);
}

.common {
  color: black;
}

.uncommon {
  color: #32a82e;
}

.rare {
  color: #5992d4;
}

.very-rare {
  color: #9810e0;
}

.legendary {
  color: #fea227;
}

.artifact {
  color: #be8972;
}

.text-input,
.number-input {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
}

.number-input {
  width: 50%;
}

.number-input:nth-of-type(1) {
  margin-right: 5px;
}

.number-input:nth-of-type(2) {
  margin-left: 5px;
}

select {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
}

.download-csv {
  font-size: 0.7rem;
  font-weight: 600;
}

.fields-menu {
  position: absolute;
  background: linear-gradient(90deg, #2f2f3d, #0b0b16);
  color: white;
  bottom: calc(100%);
  left: calc(-100% + 160px);
  font-size: 0.8rem;
  width: 120px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

.fields-menu > div {
  margin: 0px 5px;
  display: flex;
  justify-content: space-between;
}

.fa-adn {
  color: black;
  font-size: 0.8rem;
  margin: 0px;
  padding-bottom: 0.1rem;
}

.mark {
  margin: 0px;
  padding: 0px;
}

.attunement-label {
  color: black;
  width: auto;
  font-weight: 100;
  font-size: 0.8rem;
  margin-left: 5px;
}


.add-shop-menu {
  position: absolute;
  left: 100%;
  bottom: -50%;
  width: 200px;
  font-family: "montserrat", sans-serif;
  background: #0b0b16;
  color: white;
  border-radius: 5px;
  padding: 5px;
  user-select: none;
}

.add-shop-menu h3 {
  text-align: center;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}

.add-shop-menu > div {
  margin-top: 5px;
  padding: 5px;
  transition: all 0.3s ease;
  font-size: 0.8rem;
}

.add-shop-menu > div:hover {
  background: #2f2f3d;
  transition: all 0.3s ease;
}

.shop-name {
  color: #272741;
  min-height: 25px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1079px) {
 .filter-container h3 {
    font-size: 0.8rem;
  }

  .filter-container {
    display: none;
    opacity: 0;
  }

  .filter-container.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #0b0b16;
    position: fixed;
    bottom: 12vh;
    z-index: 100;
    width: 90vw;
    transition: all 0.3s ease;
    opacity: 1;
  }

  .filter {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .filter h3 {
    width: 30%;
    color: white;
  }

  .filter .text-input {
    width: 70%;
    height: 2rem;
    min-height: 2rem;
  }

  .filter .number-input {
    width: 70%;
    height: 2rem;
    min-height: 2rem;
  }

  .number-inputs {
    width: 70%;
  }

  .selected-filters {
    width: 70%;
    min-height: 2rem;
  }

  .source-header {
    flex-direction: column;
    align-items: flex-start;
    width: 30%;
    color: white;
  }

  .show-source {
    display: none;
  }

  .item .fas,
  .item-name .fas,
  .item-name .add-shop {
    opacity: 1;
  }

  .item-name {
    width: 65%;
  }
  .item-value.extended {
    width: 35%;
  }
}
</style>
