<template>
  <div class="container">
    <div class="overlay" @click="$emit('close')"></div>
    <div class="menu">
      <div class="menu-row"><h3>Download CSV</h3></div>
      <div class="menu-row">Select which fields to include in the CSV file</div>
      <div class="menu-row fields">
        <div class="field">
          <label for="name">Name</label>
          <input
            v-model="csvName"
            type="checkbox"
            id="name"
            name="name"
            value="name"
          />
        </div>
        <div class="field">
          <label for="rarity">Rarity</label>
          <input
            v-model="csvRarity"
            type="checkbox"
            id="rarity"
            name="rarity"
            value="rarity"
          />
        </div>
                <div class="field">
          <label for="attunement">Attunement</label>
          <input
            v-model="csvAttunement"
            type="checkbox"
            id="attunement"
            name="attunement"
            value="attunement"
          />
        </div>
        <div class="field">
          <label for="type">Type</label
          ><input
            v-model="csvType"
            type="checkbox"
            id="type"
            name="type"
            value="type"
          />
        </div>
        <div class="field">
          <label for="subtype">Subtype</label
          ><input
            v-model="csvSubtype"
            type="checkbox"
            id="subtype"
            name="subtype"
            value="subtype"
          />
        </div>
        <div class="field">
          <label for="value">Value</label>
          <input
            v-model="csvValue"
            type="checkbox"
            id="value"
            name="value"
            value="value"
          />
        </div>
        <div class="field">
          <label for="subvalue">Subvalue</label>
          <input
            v-model="csvSubvalue"
            type="checkbox"
            id="subvalue"
            name="subvalue"
            value="subvalue"
          />
        </div>
        <div class="field">
          <label for="source">Source</label>
          <input
            v-model="csvSource"
            type="checkbox"
            id="source"
            name="source"
            value="source"
          />
        </div>
      </div>
      <div class="menu-row">
        <JsonCSV
          class="save-button"
          @mouseover="(showCsvFields = true), (showOverlay = true)"
          :data="filteredItems"
          :fields="csvFields"
          :separator-excel="true"
          name="price_list.csv"
           @click="$emit('close')"
          >Download
        </JsonCSV>
      </div>
    </div>
  </div>
</template>

<script>
import JsonCSV from "vue-json-csv";

export default {
  name: "SaveMenu",
  components: { JsonCSV },
  props: ["filteredItems"],
  emits: ["close"],
  data() {
    return {
      csvName: true,
      csvRarity: true,
      csvAttunement:true,
      csvType: true,
      csvSubtype: true,
      csvValue: true,
      csvSubvalue: true,
      csvSource: true,
    };
  },
  computed: {
    csvFields() {
      var csvFields = [];
      if (this.csvName) {
        csvFields.push("name");
      }
      if (this.csvRarity) {
        csvFields.push("rarity");
      }
      if (this.csvAttunement) {
        csvFields.push("attunement");
      }
      if (this.csvType) {
        csvFields.push("type");
      }
      if (this.csvSubtype) {
        csvFields.push("subtype");
      }
      if (this.csvValue) {
        csvFields.push("value");
      }
      if (this.csvSubvalue) {
        csvFields.push("subvalue");
      }
      if (this.csvSource) {
        csvFields.push("source");
      }
      console.log(csvFields)
      return csvFields;
    },
  },
};
</script>

<style scoped>
.menu {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: fixed;
  width:600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #282838;
  padding: 10px;
  border-radius: 5px;
  z-index: 101;
  user-select: none;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.486);
  transition: all 1s;
  color:white;
}


label {
  margin-right: 5px;
}

input {
  cursor: pointer;
}

.fields {
  margin:20px 0px;
}

.menu-row > div {
  margin-right: 10px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
}
@media only screen and (max-width: 600px) {
  .menu {
    width:80vw;
    font-size:1.2rem;
  }
.fields {
  flex-direction:column;
}
}
</style>
