<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
* {
  margin: 0px;
}

#app {
  font-family: montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration:none;
     color: inherit;
}

h3 {
  margin-bottom: 5px;
  font-size: 1.1rem;
}


h4 {
  margin-bottom: 5px;
  font-weight:600;
  font-size: 1rem;
}

.overlay {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0, 0, 0, 0.199);
  z-index: 100;
}

.menu-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width:100%;
}

.clear {
  background:transparent;
}

.centered {
  justify-content: center;
  justify-items: center;
  align-items:center;
  align-content:center;
}

.left {
  justify-content: flex-start;
  justify-items: flex-start;
  align-items:flex-start;
  align-content:flex-start;
}

.left-center {
  justify-content:flex-start;
  align-items:center;
}

.placeholder {
     color:    rgb(130, 130, 130);
     align-self:center;
     font-size:0.9rem;
}

.tooltip {
  position:absolute;
  bottom:calc(100% + 0.7rem);
  left: 50%;
  margin-left: -3rem;
  background-color: rgb(47, 50, 79);
  width:100%;
  min-height:1rem;
  color:#fff;
  text-align:center;
  font-size:0.8rem;
  border-radius: 2px;
  font-weight:500;
  z-index: 100;
  padding: 5px;
  transform: scale(1) !important;
}

.tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -2.5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(47, 50, 79) transparent transparent transparent;
}

.pop-up {
  background:rgb(9, 9, 36);
  position:fixed;
  padding:10px;
  border-radius:5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin:0 auto;
  color:white;
  display:flex;
  flex-direction:column;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
  z-index:101;
  font-family: montserrat,sans-serif;
}

button {
  padding:10px;
  background:white;
  color:black;
  border:2px solid rgb(9, 9, 36);
  border-radius:5px;
  margin: 5px 5px 0px 5px;
  cursor:pointer;
  transition: all 0.3s ease;
  font-family:montserrat, sans-serif;
  font-weight:600;
  text-transform:uppercase;
}

button:hover {
  color:white;
  border:2px solid white;
  transition: all 0.3s ease;
  background:black;
}

.save-button {
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.save-button:hover {
  background: black;
  color: white;
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.button-ok:hover {
  background:rgb(0, 97, 21);
}

.button-cancel:hover {
  background:rgb(97, 0, 0);
}

.warning {
  color:red;
}

/* TRANSITIONS */

.drop-enter-active,
.drop-leave-active {
  transform-origin: top;
  transition: transform 0.2s ease-in-out;
}

.drop-enter-from,
.drop-leave-to {
  transform: scaleY(0);
}
.drop-enter-to,
.drop-leave-from {
  transform: scaleY(1);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.topbar-enter-active,
.topbar-leave-active {
  transition: all 0.5s ease;
}

.topbar-enter-from,
.topbar-leave-to {
  opacity: 0;
}

.topbar-enter-to,
.topbar-leave-from {
  opacity: 1;
}


</style>
