<template>
  <div>
    <div class="overlay" @click="$emit('close')"></div>
    <div class="pop-up" v-if="showDeletePopup" @click="showDeletePopup = false">
      <div class="menu-row">
        <h3>Are you sure you want to delete this shop?</h3>
      </div>
      <div class="menu-row centered">
        <button
          class="button-ok"
          @click="$store.dispatch('deleteShop', shopToDelete)"
        >
          Yes, delete
        </button>
        <button class="button-cancel">Cancel</button>
      </div>
    </div>
    <div class="drop-menu wide">
      <h3>My Shops</h3>
      <div
        class="menu-row shop-row"
        v-for="shop in $store.state.customShops"
        v-bind:key="shop.name"
      >
        <div
          class="shop-select"
          @click="$store.commit('selectShop', shop.name)"
          v-if="shopToEdit!= shop.name"
        >
          {{ shop.name }}
        </div>
        <input
          type="text"
          class="shop-select"
          :placeholder="shop.name"
          v-if="shopToEdit== shop.name"
          v-model="shopNewName"
          @keyup.enter="
            $store.dispatch('editShopName', {
              oldName: shop.name,
              newName: shopNewName,
            })
          "
        />
        <i
          class="fas fa-edit"
          @click="
            shopToEdit!= shop.name ? (shopToEdit= shop.name) : (shopToEdit= null)
          "
        ></i>
        <i
          class="fas fa-trash"
          @click="(showDeletePopup = true), (shopToDelete = shop.name)"
        ></i>
      </div>
      <button
        class="add-shop"
        @click="$emit('showShopMenu'), (showShopDrop = false)"
      >
        Create Shop
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopDropList",
  emits: ["showShopMenu", "close"],
  data() {
    return {
        showShopMenu: false,
        showDeletePopup:false,
        shopToDelete: "",
        shopToEdit: "",
        shopNewName: "",

    };
  },
};
</script>

<style scoped>



</style>