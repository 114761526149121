<template>
  <div class="shoptop-container">
    <div class="menu-row centered">
      <h2 class="shop-name" @click="editName = true, editShopDescription = false, editShopkeeperDescription = false" v-if="!editName">
        {{ $store.state.selectedShop.name }}
      </h2>
      <input
        v-else
        type="text"
        class="text-input shop-name"
        v-model="shopName"
        @blur="editName = false"
        @keyup.enter="
          $store.dispatch('editShopName', {
            oldName: $store.state.selectedShop.name,
            newName: shopName,
          }),
            (editName = false)
        "
      />
      <div class="close-container">
        <p class="close-text">Back to Price List</p>
        <i class="fas fa-times" @click="$store.commit('selectShop', null)"></i>
      </div>
    </div>
    <div class="menu-row" style="margin-bottom:10px">
      <div
        @click="editShopkeeperDescription = true, editName = false, editShopDescription = false"
        class="shop-description"
        v-if="!editShopkeeperDescription"
      >
        {{ $store.state.selectedShop.shopkeeper}}
      </div>
      <input
        v-else
        type="text"
        class="text-input shop-description"
        v-model="shopkeeperDescription"
        @blur="editShopkeerDescription = false"
        @keyup.enter="
          $store.dispatch('editShopkeeperDescription', {
            oldDescription: $store.state.selectedShop.shopkeeper,
            newDescription: shopkeeperDescription,
          }),
            (editShopkeeperDescription = false)
        "
      />
    </div>
    
    <div class="menu-row">
      <div
        @click="editShopDescription = true, editName = false, editShopkeeperDescription = false"
        class="shop-description"
        v-if="!editShopDescription"
      >
        {{ $store.state.selectedShop.description }}
      </div>
      <input
        v-else
        type="text"
        class="text-input shop-description"
        v-model="shopDescription"
        @blur="editShopDescription = false"
        @keyup.enter="
          $store.dispatch('editShopDescription', {
            oldDescription: $store.state.selectedShop.description,
            newDescription: shopDescription,
          }),
            (editShopDescription = false)
        "
      />
    </div>

  </div>
</template>

<script>
export default {
  name: "ShopTop",
  data() {
    return {
      editName: false,
      editShopDescription: false,
      editShopkeeperDescription: false,
      shopDescription: "",
      shopkeeperDescription: "",
      shopName: "",
    };
  },
  mounted() {
    this.shopName = this.$store.state.selectedShop.name;
    this.shopDescription = this.$store.state.selectedShop.description;
    this.shopkeeperDescription = this.$store.state.selectedShop.shopkeeper;
  },
  methods: {},
};
</script>

<style scoped>
.shoptop-container {
  transition: all 0.3s ease;
  margin: 15px 0px;
  padding: 20px;
  border-radius: 5px;
  background: rgb(222, 231, 235);
  position: relative;
}

.shop-name,
.shop-description {
  width: 100%;
  text-align: center;
}

.shop-name {
  margin-bottom: 5px;
}

.close-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
}

.close-container p {
  font-size: 0.8rem;
}

.fa-times {
  margin-left: 5px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .close-text {
    display: none;
  }
}
</style>
>
